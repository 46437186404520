#gallery {
  opacity: 0;
  z-index: 201;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

#gallery-content {
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  max-width: 60%;
  max-height: 80%;
  display: flex;
  overflow: hidden;
}

#gallery-header {
  color: #fff;
  text-align: center;
  background: #555;
  font-size: 26px;
}

#gallery-body {
  min-height: 0;
  display: flex;
}

#skinsUL {
  scrollbar-color: #9090a0 #fff;
  scrollbar-width: thin;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 10px 10px 0;
  display: flex;
  overflow: hidden auto;
}

#skinsUL::-webkit-scrollbar {
  width: 12px;
}

#skinsUL::-webkit-scrollbar-track {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

#skinsUL::-webkit-scrollbar-thumb {
  background: #9090a0;
  border-radius: 6px;
}

#skinsUL::-webkit-scrollbar-thumb:hover {
  background: #708090;
}

.skin {
  margin-bottom: 10px;
  margin-right: 10px;
  list-style-type: none;
}

.circular {
  cursor: pointer;
  background: url("checkerboard.6f5c998f.png") 0 0 / 150px 150px no-repeat;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  transition: all .2s;
  box-shadow: 0 0 8px #000c;
}

.skinName {
  text-align: center;
  margin-bottom: 0;
  transition: all .2s;
}

.circular:hover {
  box-shadow: 0 0 12px #000c;
}

.circular:hover + .skinName {
  text-shadow: 0 0 1px #000c;
}
/*# sourceMappingURL=index.f170e8d5.css.map */
